<template>
  <div class="plan-waitmake-container">
    <v-title>
      <Breadcrumb separator=">">
        <Breadcrumb-item>
          <i class="icon-home"></i>方案管理</Breadcrumb-item>
        <Breadcrumb-item>待审核</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <v-member-panel></v-member-panel>
      <div class="commom-panel-one">
        <Form class="search-form" :label-width="85">
          <Row>
            <Col span="6">
              <Form-item label="方案名称：">
                <span class="plan-name">{{name}}</span>
                <a :href="file" target="_blank" class="download">下载方案</a>
              </Form-item>
            </Col>
            <Col span="6">
              <template v-if="add_mark_detail !== '' ">
                <Form :label-width="60" style="display:inline-block; width:300px;" >
                  <Form-item label="备注：">
                    <a style="color: #00cb84" @click="goSee()">查看</a>
                  </Form-item>
                </Form>
              </template>
            </Col>
            <Col span="12">
            <Form-item>
              <Radio-group v-model="checkForm.result" @on-change="changeResult" type="button" size="large" class="fr">
                <Radio label="1">通过</Radio>
                <Radio label="2">不通过</Radio>
              </Radio-group>
            </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
            <Form-item label="备注：" v-if="checkForm.result === '2'">
              <Input v-model="checkForm.remark" type="textarea" :rows="4" placeholder="方案不通过时请注明原因"></Input>
            </Form-item>
            </Col>
          </Row>
        </Form>
        <div class="submit-box tac">
          <Button type="success" size="large" @click="submitcheck()">提交</Button>
        </div>
      </div>
      <p class="log-title">操作日志</p>
      <Table stripe :columns="columns" :data="logList" @on-selection-change="selectOne"></Table>
    </div>
    <!--查看方案备注modal-->
    <Modal v-model="addMarkmodal" title="查看备注">
      <div class="slotbody">
        <div class="feed-txt break-word">{{add_mark_detail}}</div>
      </div>
      <div slot="footer" class="tac">
        <Button @click="okCancel">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import vMemberPanel from 'pages/member/common/member-fixed-panel';
import planService from '@/services/planService';
export default {
	components: {
		'v-title': vTitle,
		'v-member-panel': vMemberPanel,
	},
	data() {
		return {
			checkForm: {
				plan_id: '',
				result: '',
				remark: '',
			},
			name: '',
			file: '',
			add_mark_detail: '',
			addMarkmodal: false,
			columns: [
				{ title: '操作人', key: 'operater', align: 'center' },
				{ title: '操作', key: 'info', align: 'center' },
				{ title: '说明', key: 'remark', align: 'center' },
				{ title: '时间', key: 'day', align: 'center' },
			],
		};
	},
	computed: {
		planId() {
			return this.$route.params.plan_id;
		},
	},
	created() {
		var param = { id: this.planId };
		planService.getDetail(param).then((data) => {
			this.name = data.name;
			this.file = data.file;
			this.checkForm.plan_id = data.id;
			this.logList = JSON.parse(data.history);
			this.add_mark_detail = data.add_mark;
		});
	},
	methods: {
		changeResult(result) {
			if (result === '1') {
				this.checkForm.remark = '';
			}
		},
		submitcheck() {
			if (!this.checkForm.result) {
				this.$Message.warning('请选择通过或不通过');
			} else if (this.checkForm.result === '2' && this.checkForm.remark === '') {
				this.$Message.warning('当前方案不通过，请注明原因');
			} else {
				planService.checkPlan(this.checkForm).then(() => {
					this.$router.push({ path: '/base/plan' });
				});
			}
		},
		goSee() {
			this.addMarkmodal = true;
		},
		okCancel() {
			this.addMarkmodal = false;
		},
	},
};
</script>

<style lang="css">
.plan-waitmake-container .ivu-form-item {
  margin-bottom: 14px;
}

.plan-waitmake-container .submit-box {
  margin-top: 30px;
}

.plan-waitmake-container .ivu-btn-success {
  padding: 11px 48px;
}

.plan-waitmake-container .plan-name {
  font-size: 16px;
  color: #424e67;
}
.plan-waitmake-container .download {
  margin-left: 20px;
  font-size: 16px;
  color: #ff8400;
  text-decoration: underline;
}
.break-word {
  word-break: break-all;
}
.feed-txt {
  text-indent: 2em;
  line-height: 20px;
}
</style>
